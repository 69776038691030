import React from "react";
// import { graphql, useStaticQuery } from 'gatsby';
import {Footer, GridTwo, HorizontalDivider } from '@bestfit-team/bf-base-ui';
import Layout from "../components/layout";
import ProductHero from "../sections/product/ProductHero";
import ProductImpact from "../sections/product/ProductImpact";
import ProductPartnerLogos from "../sections/product/ProductPartnerLogos";
import ProductHowItWorks from "../sections/product/ProductHowItWorks";
import ProductContactUs from "../sections/product/ProductContact";
import ProductDemographics from "../sections/product/ProductDemographics";

// TODO: #62 IMPLEMENT - product.js - resource list

const Product = ({ data }) => {
  console.log(data)
  return (
    <Layout
      pageTitle='Product'
    >
      <ProductHero /> 
      {/* <Heading h2> resource list </Heading> */}
      <GridTwo
        spansFullPage
        disableGutters 
        marginless
        heroBackgroundColor='var(--bf-core-teal)'
      >
        <p> </p>
      </GridTwo>
      <ProductHowItWorks />
      <ProductDemographics />
      <ProductImpact />
      <ProductPartnerLogos />
      <HorizontalDivider />
      <ProductContactUs />
      <Footer />
    </Layout>
  )
}

// export const query = graphql`
//   query ProductPageContentQuery {
    
//   }
// `

export default Product;