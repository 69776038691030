import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { Statistics } from '@bestfit-team/bf-base-ui';

function cleanProdDemoData (demoDataObject) {
  const dataArray = [];

  demoDataObject.map(item => {
    let itemHold = item.data;

    dataArray.push({
      value: itemHold.header,
      caption: itemHold.subheader,
    })

  })
  return dataArray;
}

const ProductDemographics = ({ ...props }) => {
  const copy = useStaticQuery(graphql`
    query ProductDemographicsQuery {
      ProductDemographicsContent: allAirtable(filter: {data: {section_title: {eq: "Who BestFit is built for"}}}
      sort: {fields: data___Segment}) {
        nodes {
          data {
            header
            subheader
          }
        }
      }
    }
  `);

  const cleanedData = cleanProdDemoData(copy.ProductDemographicsContent.nodes);
  const horiz = cleanedData.pop();

  // console.log('RENAME::::::::::: ' + JSON.stringify(cleanedData))

  return (
    <>
      <Statistics
        headingCopy={'BestFit supports students with a range of identities & responsibilities'}
        statsItems={cleanedData}
        hasHorizStat
        horizStatItem={horiz}
      />
    </>
  )
}

export default ProductDemographics;
