import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { SingleRowLogos } from '@bestfit-team/bf-base-ui';

const ProductPartnerLogos = ({datum}) => {
  const copy = useStaticQuery(graphql`
    query ProductPartnerLogosQuery {
        myLogos: airtable(data: {page_title: {eq: "Product"}, Segment: {eq: "Pilot Partners"}}) {
          id
          data {
            header
            subheader
            subheader_2
            alt_image_text
            aws_url__from_section_images_
            Images {
              data {
                aws_url
                file_name
                section_order
                alt_text
                url
                attachments {
                  url
                }
              }
            }
          }
        }
    }
  `);

console.log(JSON.stringify(copy))
  return (
      <SingleRowLogos
        version={'EducationOrganizations'}

        headingCopy={
          copy.myLogos.data.header 
        }
        subheadingCopy={
          copy.myLogos.data.subheader 
        }
        imageData={
          copy.myLogos.data.Images
        }
      />
  )
}

export default ProductPartnerLogos;
