import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { SkeletalHero } from '@bestfit-team/bf-base-ui';
import Layout from '../../components/layout';

const ProductHero = ({datum}) => {
  const copy = useStaticQuery(graphql`
    query ProductHeroQuery {
      myHero: airtable(
        data: {Segment: {eq: "product-header"}}, 
        # table: {eq: "Content"}
        ) {
          data {
            header
            status
            subheader
            action
            Segment
            action_url
            alt_image_text
            aws_url__from_section_images_
          }
      }
    }
  `);

console.log(JSON.stringify(copy))
  return (
    // <Layout>
      <SkeletalHero marginless
        variant='SevenFiveRatio'
        containerBackgroundColor='rgb(244, 244, 244)'
        // backgroundImageUrl={'https://best-fit.app/static/triangle-grey-25777a8336e851acd1255a9ef785a027.svg'}

        headingCopy={
          // datum.header || 
          copy.myHero.data.header 
        }
        subheadingCopy={
          // datum.subheader || 
          copy.myHero.data.subheader 
        }
        buttonLabel={
          // datum.action || 
          copy.myHero.data.action 
        }
        buttonUrl={
          // datum.action_url || 
          copy.myHero.data.action_url 
        }
        heroImageUrl={
          // datum.attached_images[0].url 
          // || 
          copy.myHero.data.aws_url__from_section_images_[0] 
        }
        heroImageAlt={
          // datum.attached_images[0].filename 
          // || 
          copy.myHero.data.alt_image_text 
        }
      />
    // </Layout>
  )
}

export default ProductHero;
