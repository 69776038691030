import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { ThreeStepSolution } from '@bestfit-team/bf-base-ui';

const ProductHowItWorks = ({datum}) => {
  const copy = useStaticQuery(graphql`
    query ProductHowItWorksQuery {
        myProductHowItWorks: allAirtable(
          filter: {data: {Segment: {regex: "/How it works/g"}}}
          sort: {fields: data___Segment}
        ) {
          nodes {
            data {
              header
              subheader
              subheader_2
              aws_url__from_section_images_
              alt_image_text
              attached_images {
                filename
              }
              Segment
              action
              action_url
            }
          }
        }
    }
  `);

  const coreData = copy.myProductHowItWorks.nodes[0].data;
  const stepOne = {
    stepHeaderCopy: copy.myProductHowItWorks.nodes[1].data.header,
    stepSubheadingCopy: copy.myProductHowItWorks.nodes[1].data.subheader,
    stepHeroImageUrl: copy.myProductHowItWorks.nodes[1].data.aws_url__from_section_images_[0],
    stepHeroImageAlt: copy.myProductHowItWorks.nodes[1].data.alt_image_text,
  };
  const stepTwo = {
    stepHeaderCopy: copy.myProductHowItWorks.nodes[2].data.header,
    stepSubheadingCopy: copy.myProductHowItWorks.nodes[2].data.subheader,
    stepHeroImageUrl: copy.myProductHowItWorks.nodes[2].data.aws_url__from_section_images_[0],
    stepHeroImageAlt: copy.myProductHowItWorks.nodes[2].data.alt_image_text,
  };
  const stepThree = {
    stepHeaderCopy: copy.myProductHowItWorks.nodes[3].data.header,
    stepSubheadingCopy: copy.myProductHowItWorks.nodes[3].data.subheader,
    stepHeroImageUrl: copy.myProductHowItWorks.nodes[3].data.aws_url__from_section_images_[0],
    stepHeroImageAlt: copy.myProductHowItWorks.nodes[3].data.alt_image_text,
  };
  




console.log(JSON.stringify(copy))
  return (
      <ThreeStepSolution
      version={'EducationOrganizations'}
        backgroundImageUrl={'https://best-fit.app/static/triangle-grey-25777a8336e851acd1255a9ef785a027.svg'}

        headingCopy={
          coreData.header 
        }
        subheadingCopy={
          coreData.subheader 
        }
        buttonLabel={
          coreData.action
        }
        buttonUrl={
          coreData.action_url
        }
        stepOneData={
          stepOne
        }
        stepTwoData={
          stepTwo
        }
        stepThreeData={
          stepThree
        }
      />
  )
}

export default ProductHowItWorks;
