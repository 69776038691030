import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { SingleColumnImpact } from '@bestfit-team/bf-base-ui';

const ProductImpact = ({datum}) => {
  const copy = useStaticQuery(graphql`
    query ProductImpactQuery {
      myImpact: airtable(
        data: {Segment: {eq: "Impact"}, page_title: {eq: "Product"}}
        ) {
          data {
            header
            status
            subheader
            Segment
          }
      }
    }
  `);

console.log(JSON.stringify(copy))
  return (
      <SingleColumnImpact
        backgroundImageUrl={'https://best-fit.app/static/triangle-grey-25777a8336e851acd1255a9ef785a027.svg'}

        impactCopy={
          copy.myImpact.data.header 
        }
        subheadingCopy={
          copy.myImpact.data.subheader 
        }
      />
  )
}

export default ProductImpact;
